// Import global dependencies
import './bootstrap.js';

// Import required modules
// import Tools from './modules/tools';
import Helpers from './modules/helpers';
import Template from './modules/template';
import Custom from './custom/custom';

// App extends Template
export default class App extends Template {
    
    constructor() {
        super();
    }

    _uiInit() {
        super._uiInit();
        new Custom().init();
        Helpers.run('core-bootstrap-tabs');
    }

}

jQuery.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// Once everything is loaded
jQuery(() => {
    // Create a new instance of App
   window.Dashmix = new App();
});


