export default class Custom {
    init() {
        this.formConfirmation();
        this.initFixedTooltip();
    }
    
    formConfirmation() {
        jQuery(".form-confirmation").submit(function() {
            var message = jQuery(this).data("message")
            if( typeof message == "undefined" ) message = "이걸 하겠습니까?"
            return confirm(message)
        })
    }

    initFixedTooltip() {
        if( jQuery(".fixed-tooltip").length > 0 ){
            $('.fixed-tooltip').tooltip("show");
            $('.fixed-tooltip').find(".tooltip.fade.top").removeClass("in");
        }
    }
}